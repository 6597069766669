 <template>
  <div class="AchievementNel bc">
    <div class="w">
      <!-- 搜索 -->
      <div class="tip_top">
        <div class="inputs_filter">
          <input type="text" placeholder="请输关键字" v-model="search" />
          <div @click="Search()">搜索</div>
        </div>
      </div>
      <!-- 筛选 -->
      <div class="resource-list-search">
        <div class="resource-search-content">
          <div
            class="select-content"
            v-for="(items, index) in category"
            :key="index"
          >
            <span class="select-field-title">{{ items.name }}</span>
            <div class="select-field-list">
              <ul>
                <!--  :class="key === changeTab ? 'cur' : ''"  -->
                <li
                  v-for="(item, key) in items.items"
                  :class="{ cur: key === items.index }"
                  @click="handle(key, items, item, index)"
                  :key="item.id"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="checks_wraps">
        <div class="allLists">
          <ul class="policyWraps">
            <li
              class="policyItem"
              v-for="(item, idx) in policy"
              :key="idx"
              @click="xq(item.id)"
            >
              <div class="checkLinks">
                <div class="titles">{{ item.name }}</div>
                <ul class="rightChecks">
                  <li class="checkAddress">
                    <div class="checkInner">
                      <i class="el-icon-user"></i> {{ item.holder }}
                    </div>
                  </li>
                  <li class="checkTechm">
                    <i class="el-icon-menu"></i>{{ item.territoryname }}
                  </li>
                </ul>
                <div class="titleTech">
                  <span>成果介绍：</span><span>{{ item.project_say }} </span>
                </div>
              </div>
            </li>
          </ul>
          <div class="pagination">
            <pagination
              class="pages"
              v-show="total > 0"
              :total="total"
              layout="prev, pager, next"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.limit"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  achievementsLists,
  Territory,
  Maturity,
  Hmethodad,
} from "@/common/js/api";
import Pagination from "@/common/Pagination";
 export default {
  name: "AchievementNel",
  components: { Pagination },
  data() {
    return {
      search: "",
      total: 0, //总条目数
      listQuery: {
        limit: 6,
        page: 1,
      },
      changeTab: -1,
      category: [
        {
          name: "按领域：",
          items: [],
        },
        {
          name: "按技术成熟度：",
          items: [],
        },
        {
          name: "按合作方式：",
          items: [],
        },
      ],
      FilterList: [],
      policy: [],
    };
  },
  created() {
    this.getList();
    this.getdemand();
    this.getMaturity();
    this.getHmethodad();
    this.category.forEach((item) => this.$set(item, "index", 0));
  },
  methods: {
    // 更多成果列表
    getList() {
      this.shai();
    },

    Search() {
      this.shai();
    },
    // 筛选
    handle: function (key, items, item, index) {
      items.index = key;
      this.$set(this.FilterList, index, item.id);
      this.shai();
    },
    shai() {
      var data = {
        name: this.search,
        hmethod: this.FilterList[2],
        maturity: this.FilterList[1],
        territory: this.FilterList[0],
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      };
      achievementsLists(data)
        .then((res) => {
          this.public(res);
        })
        .catch(() => {});
    },
    public(res) {
      this.policy = res.data.data;
      this.total = res.data.total;
      for (let i = 0; i < this.policy.length; i++) {
        this.policy[i].territoryname = this.policy[i].territory.name;
      }
    },
    // 领域
    getdemand() {
      Territory()
        .then((res) => {
          var b = { name: "全部" };
          res.data.unshift(b);
          this.category[0].items = res.data;
        })
        .catch(() => {});
    },
    // 技术成熟度
    getMaturity() {
      Maturity()
        .then((res) => {
          var b = { name: "全部" };
          res.data.unshift(b);
          this.category[1].items = res.data;
        })
        .catch(() => {});
    },
    // 合作方式
    getHmethodad() {
      Hmethodad()
        .then((res) => {
          var b = { name: "全部" };
          res.data.unshift(b);
          this.category[2].items = res.data;
        })
        .catch(() => {});
    },
    // 详情
    xq(id) {
      this.$router.push({
        path: "/Achievement_details",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tip_top {
  width: 100%;
  //   height: 34px;
  background-color: #3892eb;
  padding: 5px 0;
  color: #fff;
  padding: 9px 9px 10px 17px;
  line-height: 36px;
  margin-top: 135px;
  .inputs_filter {
    width: 100%;
    height: 34px;
    line-height: 34px;
    border-radius: 4px;
    cursor: pointer;
    input {
      display: inline-block;
      background-color: #fff;
      border-radius: 20px;
      height: 34px;
      width: 1074px;
      padding: 4px 20px;
      line-height: 26px;
      box-sizing: border-box;
    }
    div {
      display: inline-block;
      width: 90px;
      height: 34px;
      text-align: center;
    }
  }
}
.resource-list-search {
  width: 1200px;
  box-sizing: border-box;
  padding: 10px 40px 25px 20px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  .select-content {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    font-size: 14px;

    .select-field-title {
      width: 100px;
      color: #101010;
      flex: none;
      line-height: 27px;
    }
    .select-field-list {
      width: 1000px;
      display: inline-block;
      li {
        padding: 0 15px;
        display: inline-block;
        color: #999;
        cursor: pointer;
        height: 27px;
        line-height: 27px;
        margin-bottom: 5px;
      }
      li.cur {
        background: #2a71d2;
        border-radius: 2px;
        color: #fff;
      }
    }
  }
}
.checks_wraps {
  padding: 10px 25px 25px 20px;
  background: #ffffff;
  cursor: pointer;
  .policyItem {
    border-bottom: 1px solid rgba(237, 237, 237, 100);

    .titles {
      margin-top: 17px;
      margin-bottom: 11px;
      color: rgba(16, 16, 16, 100);
      font-size: 18px;
      text-align: left;
      font-weight: 700;
    }
    .rightChecks > li {
      display: inline-block;
      margin-right: 28px;
      font-size: 14px;
      i {
        color: #3892eb;
        margin-right: 8px;
      }
    }
    .rightChecks {
      margin-bottom: 13px;
      .activeprice {
        float: right;
        color: rgba(255, 59, 59, 100);
        font-size: 18px;
      }
    }
    .titleTech {
      margin-bottom: 11px;
      line-height: 1.5;
    }
  }
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
		overflow: hidden;
	}
	.tip_top{
		margin-top: 15rem;
		padding: 1.2rem;
	}
	.resource-list-search{
		width: 98%;
		overflow: hidden;
	}
	.tip_top .inputs_filter input{
		width: 40rem;
		height: 2rem;
		font-size: 1.6rem;
	}
	.tip_top .inputs_filter div{
		font-size: 1.4rem;
	}
	.resource-list-search .select-content .select-field-title{
		font-size: 1.4rem;
		width: 10rem;
	}
	.resource-list-search .select-content .select-field-list li{
		font-size: 1.4rem;
		height: 2rem;
		line-height: 2rem;
	}
	.checks_wraps .policyItem .titles{
		font-size: 1.8rem;
	}
	.checks_wraps .policyItem .titleTech{
		font-size: 1.4rem;
	}
}
</style>