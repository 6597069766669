<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 成果推荐---- 更多成果 -->
    <AchievementNel></AchievementNel>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import AchievementNel from "./base/AchievementNel";
export default {
  name: "Achievement",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
       this.ConList = item;
    },
  },
  components: {
    ConTab,
    AchievementNel,
  },
};
</script>

<style scoped>
</style>
